import { channel, eventChannel, buffers, END } from 'redux-saga'
import { select, take, takeEvery, put, call, all, fork } from 'redux-saga/effects'

import { importsFilesRoutine } from './routines'

import { makeSelectedCandidatesFilesSelector, makeChosenFilesSelector, makeFilesSelector, makeUpdateDocumentsByFolderSelector} from './selectors'
import { childDocumentsByFolderSeletor, photoSerieSelector } from '../Documents/selectors'
import { makeParamsSelector } from '../Params/selectors'

import { documents, getToken } from '../../utils/api'

import { putDocumentRoutine, postDocumentRoutine, documentCacheRoutine } from '../Documents/routines'
import { makeCachesSelector } from '../Documents/selectors'


import { normalize } from 'normalizr';

import { doc } from '../schemas'

// eslint-disable-next-line
//import JimpWorker from 'worker-loader!../../utils/workers/jimp.worker.js'

// eslint-disable-next-line
//import JPEGWorker from 'worker-loader!../../utils/workers/jpeg.worker.js'

import _ from 'lodash'

import Pica from 'pica'

import "@cephalomax/context-filter-polyfill"

// const createThumb2 = async (url) => {

//   const jimpWorker = new JimpWorker();

//   const workerResponse = new Promise(function(resolve, reject) {
//     jimpWorker.onmessage = (e) => {
//       resolve(e.data); // done
//     }
//   })

//   jimpWorker.postMessage({ url, operations : { resize: 250 }, output: 'url' });

//   return await workerResponse;

// }




const createThumb = async (url) => {
  
  const thumbSize = 250;

  let originalImg = document.createElement('img') 
  originalImg.src = url
  
  await new Promise(resolve => { 
    originalImg.onload = resolve
    if(originalImg.naturalWidth)
      resolve()
  })

  const w = originalImg.naturalWidth
  const h = originalImg.naturalHeight
  const scale = w >= h ? thumbSize / w : thumbSize / h
  
  let canvas = document.createElement('canvas')
  canvas.width =  Math.floor(scale * w)
  canvas.height = Math.floor(scale * h)

  const ctx = canvas.getContext('2d', {alpha: false})
  ctx.fillStyle = '#fff';
  ctx.fillRect(0,0,canvas.width, canvas.height)
  
  ctx.drawImage(originalImg, 0, 0, canvas.width, canvas.height)
  
  //URL.revokeObjectURL(originalImg.src) 

  const thumbUrl =  canvas.toDataURL('image/jpeg')

  originalImg = null
  canvas = null

  return thumbUrl
  // return await new Promise((resolve, reject) => { 
  //   canvas.toBlob(b => resolve(URL.createObjectURL(b)))
  // })
}

function* createThumbSaga(file) {


  const url = URL.createObjectURL(file)
  const thumb = yield call(createThumb, url)

  yield put(importsFilesRoutine.addFiles({
    entities: {
      files: {
        [file.name]: {url , thumb}
      }
    }
  }))

}


function* dropImportsFilesSaga(action) {

  try {
    console.log('dropImportsFilesSaga', action)

    // yield put(importsFilesRoutine.addfiles({
    //   entities: {
    //     files: _.keyBy(action.payload, 'name')
    //   }
    // }))

    yield all(action.payload.map(file => fork(createThumbSaga, file)))

  }
  catch(error) {
    console.log(error)
  } 

}

function* watchDropImportsFiles() {
  yield takeEvery(importsFilesRoutine.DROP_FILES, dropImportsFilesSaga)
}

const selectedCandidatesSelector = makeSelectedCandidatesFilesSelector()

function* autoSortFiles(action) {

  try {
    const { scheme, folderId } = action.payload

    //console.log(action.payload)

    //const selectedCandidatesSelector = yield call(makeSelectedCandidatesFilesSelector)

    const selectedCandidates = yield select(selectedCandidatesSelector, folderId)

    const chosens = {}  

    selectedCandidates.forEach((candidate, index) => {
      const tileScheme = scheme[index];
      const cropData = {
        scaleX: tileScheme.flipH ? -1 : 1, 
        scaleY: tileScheme.flipV ? -1 : 1,
        rotate: tileScheme.rotation
      }
      chosens[tileScheme.id] = {...candidate, cropData};
    })

     yield put(importsFilesRoutine.addChosens({folderId, chosens}))
     yield put(importsFilesRoutine.removeCandidates({folderId}))
  }
  catch(error) {
    console.log(error)
  }
}

function* watchAutoSortFiles() {
  yield takeEvery(importsFilesRoutine.AUTO_SORT, autoSortFiles)
}

const filesSelector = makeFilesSelector()
const chosenFilesSelector = makeChosenFilesSelector()
const paramsSelector = makeParamsSelector()
const updateDocumentsSelector = makeUpdateDocumentsByFolderSelector()
const cachesSelector = makeCachesSelector()

function* cropAndUploadFiles(mainChan, action) {
  


  const { folderId, patientId } = action.payload

  //select params
  //const paramsSelector = yield call(makeParamsSelector)
  const  { 0 : { value : { photos : photosParams} } } =  yield select(paramsSelector, 'global')

  

  //select documents
  
  //select chosenFiles
  //const chosenFilesSelector = yield call(makeChosenFilesSelector)
  //get chosen files from store
  const chosenFiles = yield select(chosenFilesSelector, folderId)
  const chosenFilesClean = _.pickBy(chosenFiles || {}, _.identity);

  const updateDocuments = yield select(updateDocumentsSelector, { folderId })
  const updateDocumentsClean = _.pickBy(updateDocuments || {}, _.identity);
  const cachedUrls = yield select(cachesSelector,  Object.values(updateDocumentsClean).map(({ id }) => id))

  const docs = yield select(childDocumentsByFolderSeletor, { folderId })
  //const filesSelector = yield call(makeFilesSelector)
  const files = yield select(filesSelector, Object.values(chosenFilesClean))

  console.log('cropAndUploadFiles chosenFiles', chosenFiles, files, folderId, patientId)

  //task order from params could be better
  const baseTask = {}
  //let updateTask = {}

  if(photosParams.keepOriginal) {
    if(photosParams.resizeOriginal) {
      baseTask.operations = {
        resize: {
          size: {
            upload: {},
            crop: {
              upload: {}
            }
          }
        }
      }
      baseTask.max = photosParams.resizeOriginalMaxSize

      // updateTask.operations = {
      //   resize: {
      //     upload: {},
      //     crop: {
      //       upload: {}
      //     }
      //   }
      // }
      // updateTask.max = photosParams.resizeOriginalMaxSize
    }
    else {
      baseTask.operations = {
        size: {
          upload: {},
          crop: {
            upload: {}
          }
        }
      }

      // updateTask.operations = {
      //   upload: {},
      //   crop: {
      //     upload: {}
      //   }
      // }
    }
  }
  else {
    if(photosParams.resizeCropped) {
      baseTask.operations = {
        crop: {
          resize: {
            size: {
              upload: {}
            }
          }
        }
      }
      baseTask.max = photosParams.resizeCroppedMaxSize
    }
    else {
      baseTask.operations = {
        crop: {
          size: {
            upload: {}
          }
        }
      }
    }

    //updateTask = {...baseTask}
  }

  let tasks = Object.keys(chosenFilesClean).map(filename => ({
    name: `${filename}`,
    mimeType: 'image/jpeg',
    url: files[chosenFiles[filename].id].url,
    cropData: {...chosenFiles[filename].cropData},
    imageSettings: {...chosenFiles[filename].imageSettings},
    parent_doc: folderId,
    parent_patient: patientId,
    ...baseTask
  }))
  console.log('updateDocumentsClean', updateDocumentsClean)
  let updateTasks = Object.keys(updateDocumentsClean).map(filename => {
    const doc_original = _.find(docs, doc => doc.id === updateDocumentsClean[filename].id)
    const doc_cropped = _.find(docs, doc => doc.name === filename)

    console.log('updateDocumentsClean', doc_original, doc_cropped)
    //erreur ici url doit être récupérée depuis le cache

    return {
      name: `${filename}`,
      //mimeType: 'image/jpeg',
      type: 'update',
      doc_cropped_id: doc_cropped.id,
      doc_original_id: doc_original.id,
      initialCropData: doc_cropped.data.initialCropData || doc_cropped.data.cropData,
      url: cachedUrls[doc_original.id],
      cropData: {...updateDocumentsClean[filename].cropData},
      imageSettings: {...updateDocumentsClean[filename].imageSettings},
      parent_doc: folderId,
      parent_patient: patientId,
      ...baseTask
    }
  })

  yield all([...tasks, ...updateTasks].map(task => put(importsFilesRoutine.increaseLoading({folderId: task.parent_doc}))))
  
  //prévent save button to appear before ending
  if(photosParams.keepOriginal) {
    yield all(tasks.map(task => put(importsFilesRoutine.increaseLoading({folderId: task.parent_doc}))))
  }
  yield all([...tasks, ...updateTasks].map(task => put(mainChan, task)))

  tasks = null
  updateTasks = null
}

const normalizeAngle = ( value, start = -90, end = 90) => {
  const width       = end - start   ;   // 
  const offsetValue = value - start ;   // value relative to 0

  return ( offsetValue - ( Math.floor( offsetValue / width ) * width ) ) + start ;
  // + start to reset back to start of original range
}

const getImageFromUrl = (url) => new Promise((resolve, reject) => {
  const img = document.createElement('img')
  img.crossOrigin = "Anonymous"
  img.referrerPolicy = "no-referrer"
  img.onload = (e) => resolve(e.target)
  img.src = url
})

const getCanvasFromSize = (size) => {
  const canvas = document.createElement('canvas')
  canvas.width = size.width
  canvas.height = size.height
  return canvas
}

//input img or canvas elt
const getCroppedCanvasFromInput = async (inputElt, cropData) => {
  
  const w = inputElt.naturalWidth || inputElt.width
  const h = inputElt.naturalHeight || inputElt.height

  const alphaRad = normalizeAngle(cropData.rotate)*Math.PI/180
  const alphaRadAbs = Math.abs(alphaRad)
  
  let x, y
  
  if(Math.sign(alphaRad) === 1) {
    x = Math.floor(Math.cos(alphaRadAbs)*Math.sin(alphaRadAbs)*h)
    y = - Math.floor(Math.sin(alphaRadAbs)*Math.sin(alphaRadAbs)*h)
  } 
  else {
    x = - Math.floor(Math.sin(alphaRadAbs)*Math.sin(alphaRadAbs)*w)
    y = Math.floor(Math.cos(alphaRadAbs)*Math.sin(alphaRadAbs)*w)
  }

  const canvasSize = {
    width: Math.floor(Math.sin(alphaRadAbs)*h + Math.cos(alphaRadAbs)*w),
    height: Math.floor(Math.cos(alphaRadAbs)*h + Math.sin(alphaRadAbs)*w)
  }

  let canvas = getCanvasFromSize(canvasSize)
  const ctx = canvas.getContext('2d', {alpha: false})
  
  ctx.fillStyle = "#fff"
  ctx.fillRect(0, 0, canvasSize.width, canvasSize.height);

  ctx.translate(canvas.width/2,canvas.height/2)
  ctx.scale(cropData.scaleX, cropData.scaleY)
  ctx.translate(-canvas.width/2,-canvas.height/2)

  ctx.rotate(alphaRad);
  //ctx.scale(cropData.scaleX, cropData.scaleY)

  ctx.drawImage(inputElt, x, y, w, h)
  ctx.setTransform(1, 0, 0, 1, 0, 0)

  const canvasCroppedSize = {
    width: Math.floor(cropData.width),
    height: Math.floor(cropData.height)
  }

  const canvasCropped = getCanvasFromSize(canvasCroppedSize)
  const ctxCropped = canvasCropped.getContext('2d', {alpha: false})



  ctxCropped.drawImage(canvas, Math.floor(cropData.x), Math.floor(cropData.y), Math.floor(cropData.width), Math.floor(cropData.height), 0, 0, canvasCroppedSize.width, canvasCroppedSize.height)
  canvas = null
  return canvasCropped
}

const getCroppedCanvasFromInput2 = async (inputElt, cropData, imageSettings) => {
  
  const w = inputElt.naturalWidth || inputElt.width
  const h = inputElt.naturalHeight || inputElt.height
  
  const alphaRad = cropData.rotate*Math.PI/180 
  const degree = Math.abs(cropData.rotate) % 180;
  
  const arc = ((degree % 90) * Math.PI) / 180;
  const sinArc = Math.sin(arc);
  const cosArc = Math.cos(arc);
  const newWidth = (w * cosArc) + (h * sinArc);
  const newHeight = (w * sinArc) + (h * cosArc);
  
  const canvasSize = degree >= 90 ? {
    width: Math.round(newHeight),
    height: Math.round(newWidth)
  } : {
    width: Math.round(newWidth),
    height: Math.round(newHeight)
  }

  let canvas = getCanvasFromSize(canvasSize)
  const ctx = canvas.getContext('2d', {alpha: false})
 
  const params = [-w/2, -h/2, w, h]
   
  ctx.fillStyle = "#fff"
  ctx.fillRect(0, 0, canvasSize.width, canvasSize.height);
  ctx.filter = imageSettings? `contrast(${imageSettings.contrast}%) brightness(${imageSettings.brightness}%)` : '';

  ctx.save();
  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate(alphaRad);
  ctx.scale(cropData.scaleX, cropData.scaleY);
  ctx.drawImage(inputElt, ...params.map((param) => Math.round(param)));
  ctx.restore();

  const canvasCroppedSize = {
    width: Math.round(cropData.width),
    height: Math.round(cropData.height)
  }

  const canvasCropped = getCanvasFromSize(canvasCroppedSize)
  const ctxCropped = canvasCropped.getContext('2d', {alpha: false})
  
  ctxCropped.fillStyle = "#fff"
  ctxCropped.fillRect(0, 0, canvasCroppedSize.width, canvasCroppedSize.height)

  const croppedParams = [-cropData.x, -cropData.y, canvas.width, canvas.height]


  /*
  cropData.x < 0 || cropData.y < 0 ?
    [0,0, canvas.width, canvas.height, Math.abs(cropData.x), Math.abs(cropData.y), canvas.width, canvas.height] :
      [cropData.x, cropData.y, cropData.width, cropData.height, 0, 0, canvasCroppedSize.width, canvasCroppedSize.height]
  */
  ctxCropped.drawImage(canvas, ...croppedParams.map((param) => Math.round(param)))
  

  inputElt = null
  canvas = null

  return canvasCropped
}

const getCanvasFromImage = (img) => {

  const w = Math.round(img.naturalWidth)
  const h = Math.round(img.naturalHeight)

  const canvas = getCanvasFromSize({width: w, height: h})
  const ctx = canvas.getContext('2d')
  ctx.fillStyle = "#fff"
  ctx.fillRect(0, 0, w, h)
  ctx.drawImage(img, 0, 0, w, h)

  return canvas
}

const resizer = new Pica({
  tile: 512,
  idle: 10000,
  features: ['js', 'wasm']//['js', 'wasm', 'ww'], //remove 'ww' webworker from list car bug sous chrome dans l ordre des tiles
});

//input img or canvas elt
const getResizedCanvasFromInput = async (inputElt, max)  => {

  const w = Math.round(inputElt.naturalWidth || inputElt.width)
  const h = Math.round(inputElt.naturalHeight || inputElt.height)

  let scale_factor = max / Math.max(w, h);
  
  if (scale_factor >= 1) 
    return {
      resizedCanvas: inputElt,
      scale: 1
    }

  const outCanvasSize = {
    width: Math.round(Math.max( Math.round(w * scale_factor), 1 )),
    height: Math.round(Math.max( Math.round(h * scale_factor), 1 ))
  }

  const outCanvas = getCanvasFromSize(outCanvasSize)

  const resizedCanvas = await resizer.resize(inputElt, outCanvas, {
    unsharpAmount: 160,
    unsharpRadius: 0.6,
    unsharpThreshold: 1,
  });

  inputElt = null

  return {
    resizedCanvas: resizedCanvas,
    scale: scale_factor
  }
}

const getBlobFromCanvas = async (canvas, mimeType = 'image/jpeg', quality = 0.8) => {
  


  return await new Promise((resolve, reject) => {
    canvas.toBlob(resolve, mimeType, quality, )
    canvas = null
  })
}

/*
const getJPEGBlob = async (canvas, quality = 0.8, worker) => {

  let context = canvas.getContext('2d', {alpha: false});
  let imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  
  const workerResponse = new Promise(function(resolve, reject) {
    worker.onmessage = (e) => {
      console.log('getJPEGBlob response onmessage', e)
      resolve(new Blob( [e.data.data], {type: 'image/jpeg'} )); // done
      e.data.data = null
      //console.log(imageData.data.length, 'onmessage now empty?')
    }
  })
  console.log('getJPEGBlob before postMessage')
  worker.postMessage({
    buffer: imageData.data.buffer,
    width: imageData.width,
    height: imageData.height,
    quality: quality*100
  },[imageData.data.buffer]);

  canvas = null
  context = null
  imageData = null
  
  return await workerResponse;
  
}
*/


function* handleResize(inChan, outChan) {
  while (true) {
    let task = yield take(inChan)
    //console.log('handleResize task', task)
    let input = task.canvas;
    if(!input)
      input = yield call(getImageFromUrl, task.url)

    console.log(input)

    let result = yield call(getResizedCanvasFromInput, input, task.max)
    console.log(result)
    let newTask = {scale:result.scale, ...task,};
    if(result.scale !== 1) {
      if(newTask.cropData) {
        newTask.cropData.x *= result.scale
        newTask.cropData.y *= result.scale
        newTask.cropData.width *= result.scale
        newTask.cropData.height *= result.scale
        newTask.canvas = result.resizedCanvas
      }
    }

    console.log('handleResize end')
    //renvoyer vers chan de sortie
    yield put(outChan, newTask)

    newTask = null
    task = null
    input = null
    result = null
  }
}


//inChan payload with url and cropData
//outChan payload with canvas cropped
function* handleCrop(inChan, outChan) {
  while (true) {
    
    let task = yield take(inChan)
    
    let input = task.canvas;
    if(!input)
      input = yield call(getImageFromUrl, task.url)

    let croppedCanvas = yield call(getCroppedCanvasFromInput2, input, task.cropData, task.imageSettings)
    
    //renvoyer vers chan de sortie
    yield put(outChan, { ...task, cropped: true, canvas : croppedCanvas })

    input = null
    croppedCanvas = null
    task = null
  }
}

const createUploadFileChannel = (url, file) => {
    return eventChannel(emitter => {
        
        let cancel = () => {};
        
        const onProgress = (progressEvent) => {
          console.log(progressEvent)
          const progress = Math.round(100*progressEvent.loaded / progressEvent.total);
          emitter({ progress })
        }
        
        documents.upload(url, file, onProgress, cancel)
          .then(result => {
            emitter({ success: result });
          })
            .catch(e => {
              emitter({ error: e });
            })
              .finally(() => {
                emitter(END);
              })
        
        return cancel
    }, buffers.sliding(2));
}

function* uploadFile(id, url, file) {
  const channel = yield call(createUploadFileChannel, url, file);

  while (true) {
    const { progress = 0, error, success } = yield take(channel);
    if (error) {
        yield put(importsFilesRoutine.errorUpload({ id, error }));
        return error;
    }
    if (success) {
        yield put(importsFilesRoutine.successUpload({ id}));
        return success;
    }
    yield put(importsFilesRoutine.progressUpload({ id, progress }));
  }
}

function* handlePrepareUpload (inChan, outChan, worker) {
  while (true) {
    let task = yield take(inChan)

    let canvas = task.canvas;
    if(!canvas){
      let img = yield call(getImageFromUrl, task.url)
      canvas = yield call(getCanvasFromImage, img)
      img = null
    }

    //let blob = yield call(getJPEGBlob, canvas, 0.8, worker)
    let blob = yield getBlobFromCanvas(canvas)
    canvas = null
    let file = new File([blob], `${task.name}${task.cropped ? '' : '_original'}.jpg`, {
      type: "image/jpeg",
    })

    if(task.type === 'update')
      yield put(putDocumentRoutine.request())
    else
      yield put(postDocumentRoutine.request())

    try {

      const token = yield select(getToken);

      let response;

      if(task.type === 'update') {
        response = yield call(documents.put, 
            token, 
            task.cropped ? task.doc_cropped_id : task.doc_original_id ,
            {
              data: {
                initialCropData: task.initialCropData,
                cropData: task.cropData,
                imageSettings: task.imageSettings,
                size: blob.size
              },
              uploaded: !(task.cropped || (task.scale && task.scale !== 1)),
            });
      }
      else {
        response = yield call(documents.post, token, {
          name: `${task.name}${task.cropped ? '' : '_original'}`,
          data: {
            cropData: task.cropData,
            imageSettings: task.imageSettings,
            size: blob.size,
          },
          type: "image",
          parent_patient: task.parent_patient,
          parent_doc: task.parent_doc,
          mimetype: "image/jpeg"
        });
      }

      let { data } = response

      data.data.thumbnailLink = URL.createObjectURL(file)

      const normalizedData = yield call(normalize, data, doc);

      if(task.type === 'update')
        yield put(putDocumentRoutine.success(normalizedData));
      else
        yield put(postDocumentRoutine.success(normalizedData));
      

      yield put(outChan,  {...task, file, doc : data } )

      response = null
      data = null
    }
    catch(error) {
      console.log(error)
      if(task.type === 'update')
        yield put(putDocumentRoutine.failure(error))
      else
        yield put(postDocumentRoutine.failure(error));
    } 
    if(task.type === 'update')
      yield put(putDocumentRoutine.fulfill())
    else
      yield put(postDocumentRoutine.fulfill());
    

    task = null
    blob = null
    file = null
  }
}

function* handleUpload (inChan, outChan) {
  while (true) {
    let task = yield take(inChan)
    //console.log('handleUpload', task)
    
    try {
      if(task.type === 'update'){
        //si cropped upload every time or if original resized (!!scale) && scale change (!== 1)
        if(task.cropped || (task.scale && task.scale !== 1)) {
          yield put(importsFilesRoutine.addUpload({
            id: task.doc.id,
            url: task.doc.data.thumbnailLink,
            title: task.doc.name,
          }))
          yield call(uploadFile, task.doc.id, task.doc.data.uploadUrl, task.file)
          task.uploaded = true
        }
      }
      else {
        yield put(importsFilesRoutine.addUpload({
          id: task.doc.id,
          url: task.doc.data.thumbnailLink,
          title: task.doc.name,
        }))
        yield call(uploadFile, task.doc.id, task.doc.data.uploadUrl, task.file)
        task.uploaded = true
      }
      //get document here or in another chan?
      //upload ok set uploaded to server
      
      if(task.uploaded) {
        yield put(putDocumentRoutine.request())
        const token = yield select(getToken);
        let response = yield call(documents.put, 
          token, 
          task.doc.id ,
          {
            uploaded: true,
          }
        );
        let { data } = response
        

        //yield put(importsFilesRoutine.successUpload({ id: task.doc.id, url: data.data.thumbnailLink}));
        //URL.revokeObjectURL(task.doc.data.thumbnailLink)

        const normalizedData = yield call(normalize, data, doc);
        yield put(putDocumentRoutine.success(normalizedData));
      
      }
      
      //renvoyer progress vers chan de sortie
      yield put(outChan,  {...task })

    }
    catch(error) {
      console.log(error)
    } 

    
    task = null
  }
}

function* handleEnd (inChan) {
  while (true) {
    let task = yield take(inChan)

    if(task.uploaded){
      //delete all cached version
      yield put(documentCacheRoutine.deleteAll({id: task.doc.id}))
      //get new thumbnailURL
      //yield put(getDocumentRoutine.trigger({id: task.doc.id}))
    }
    
    if(task.type === 'update') //remove updateDocument
      yield put(importsFilesRoutine.removeUpdateDocument({ folderId: task.parent_doc, photoId: task.name }))
    else //remove chosenFile
      yield put(importsFilesRoutine.removeChosen({ folderId: task.parent_doc, photoId: task.name }))   

    yield put(importsFilesRoutine.decreaseLoading({folderId: task.parent_doc}))

    console.log('handleEnd', task)
    task = null
  }
}

function* handleSize (inChan, outChan) {
  while (true) {
    
    let task = yield take(inChan)
    
    let input = task.canvas;
    if(!input)
      input = yield call(getImageFromUrl, task.url)
    
    //renvoyer vers chan de sortie
    yield put(outChan, { 
      ...task, 
      cropData: {
        ...(task.cropData || {}), 
        original_height: input.naturalHeight || input.height,
        original_width: input.naturalWidth || input.width
    } })

    input = null
    task = null
  }
}

function* handleOperations (channels) {
  while(true) {
    let task = yield take(channels.mainChan)
    yield all(
      Object.keys(task.operations || {}).map(operation => {
        let result
        const nextTask = {...task, operations: { ...task.operations[operation]} }
        switch(operation) {
          case 'resize':
            result = put(channels.inResizeChan, nextTask)
          break;
          case 'crop':
            result = put(channels.inCropChan, nextTask)
          break;
          case 'upload':
            result = put(channels.inPrepareUploadChan, nextTask)
          break;
          case 'size': 
            result = put(channels.inSizeChan, nextTask)
          default:
            //result = put(channels.inEndChan, nextTask)
          break;
        }
        return result
    }))
    console.log('handleOperations', task, task.operations, Object.keys(task.operations).length)
    if(Object.keys(task.operations).length === 0)
      yield put(channels.inEndChan, task)
    
    task = null
  }
}

function* watchCropAndUploadFiles(uploadThreads = 30, cropThreads = 30, resizeThreads = 30) {

  const mainChan = yield call(channel)

  const inCropChan = yield call(channel)

  const inResizeChan = yield call(channel)

  const inPrepareUploadChan = yield call(channel)

  const inUploadChan = yield call(channel)

  const inEndChan = yield call(channel)

  const inSizeChan = yield call(channel)

  const channels = { 
    inCropChan, 
    inResizeChan,
    inPrepareUploadChan, 
    inUploadChan,
    inEndChan,
    inSizeChan,
    mainChan
  }

  // create worker 'threads' 
  for (let i = 0; i < cropThreads; i++) {
    yield fork(handleCrop, inCropChan, mainChan)
  }

  // create worker 'threads'
  for (let i = 0; i < resizeThreads; i++) {
    yield fork(handleResize, inResizeChan, mainChan)
  }

  //
  for (let i = 0; i < uploadThreads; i++) {
    //const worker = new JPEGWorker()
    //yield fork(handlePrepareUpload, inPrepareUploadChan, inUploadChan, worker)
    yield fork(handlePrepareUpload, inPrepareUploadChan, inUploadChan)
  }

  for (let i = 0; i < uploadThreads; i++) {
    yield fork(handleUpload, inUploadChan, mainChan)
  

    yield fork(handleSize, inSizeChan, mainChan)

    yield fork(handleEnd, inEndChan)

    yield fork(handleOperations, channels)
  }
  console.log('watchCropAndUploadFiles')
  yield takeEvery(importsFilesRoutine.CROP_AND_UPLOAD, cropAndUploadFiles, mainChan)
}

const exo_serie = [
  'exo_frontal',
  'exo_frontal_smiling',
  'exo_lateral',
  'exo_lateral_smiling',
  'exo_semi_lateral',
  'exo_semi_lateral_smiling',
]

const endo_serie = [
  'endo_frontal_occlusion',
  'endo_right_occlusion',
  'endo_left_occlusion',
  'endo_frontal_open',
  'endo_maxilla',
  'endo_mandible',
]

const print_serie = [
  'print_frontal_occlusion',
  'print_right_occlusion',
  'print_left_occlusion',
  'print_maxilla',
  'print_mandible',
]

const series = {
  exo_serie,
  endo_serie,
  print_serie,
}

const seriesMap = [
  ...print_serie.map(photoId => [photoId, 'print_serie']),
  ...endo_serie.map(photoId => [photoId, 'endo_serie']),
  ...exo_serie.map(photoId => [photoId, 'exo_serie']),
]

const photoIdToSerie = Object.fromEntries(seriesMap)


function* handleApplyImageSettings(action) {
  const {folderId, photoId, imageSettings} = action.payload
  const serie = series[photoIdToSerie[photoId]] || []

  //documents non sauvegardés
  const chosenFiles = yield select(chosenFilesSelector, folderId) //return object
  //documents sauvergadés
  const documents = yield select(photoSerieSelector, {folderId, serie})  //return array
  //documents sauvergadés en cours de modification
  const updateDocuments = yield select(updateDocumentsSelector, { folderId })  //return object
  console.log('chosenFiles', chosenFiles)
  // Pour les chosen files on va faire un update chosen sur les photos de la série
  yield all(Object.entries(chosenFiles || {}).filter(([photoId, doc]) => serie.includes(photoId)).map(([photoId, doc]) => {
    return put(importsFilesRoutine.updateChosen({ folderId, photoId, chosen: {
      ...doc,
      imageSettings,
      meta: 'useEffect'
    }}))
  }))
  //Pour updateDocuments on va faire un UPDATE_DOCUMENT mais en tenant compte des modifications déjà présentes
  yield all(Object.entries(updateDocuments || {}).filter(([photoId, doc]) => serie.includes(photoId)).map(([photoId, doc]) => {
    return put(importsFilesRoutine.updateDocument({ folderId, photoId, document: {
      ...doc,
      imageSettings,
      meta: 'useEffect'
    }}))
  }))
  //Pour les documents sauvegadés non modifiés = ( documents - updateDocuments)  on va faire un UPDATE_DOCUMENT mais à partir des données sauvegardées
  yield all((documents || []).map(doc => {
    if(doc.name.endsWith('_original')) {
      const photoId = doc.name.slice(0,-9)
      return put(importsFilesRoutine.updateDocument({ folderId, photoId, document: {
        id: doc.id,
        cropData: doc.data.cropData,
        imageSettings,
        meta: 'useEffect'
      }}))
    }
    else {
      //on verra si on revient sur le dontkeeporiginalPhoto
      //pour le moment pas la peine de gérer ce cas de figure
    }
    return undefined
  }))

}

function* watchApplyImageSettings() {
  yield takeEvery(importsFilesRoutine.APPLY_IMAGE_SETTINGS, handleApplyImageSettings)
}

function* watchImportsFiles() {
  yield all([
    watchDropImportsFiles(),
    watchAutoSortFiles(),
    watchCropAndUploadFiles(),
    watchApplyImageSettings(),
  ]);
}

export default watchImportsFiles;